.positionAlert {
  position: absolute;
  bottom: 10%;
  left: 36%;
  width: 30rem;
}
.pages-marginLeft {
  margin-left: 60rem;
}
.pages-marginLeftCities {
margin-left: 50rem;
}
.sidebarCustomFramePadding {
font-weight: 400 !important;
padding: 0 .8rem;
border-right: 4px solid transparent;
border-left: 4px solid transparent;
}
.sidebarCustomFrame {
background-color: #6365f14a;
border-radius: 5rem;
border-right: 4px solid #3c3efcb0;
border-left: 4px solid #3c3efcb0;
}
.text-purple {
color: #393bf7;
}
.text-purple-light:hover {
color: #393bf7;

}
.text-purple-light-icon:hover {
transition: all .2s;
transform: scale(1.30);

}
.text-purple-heavier:hover {
color: #2427f7;
}
#gradient-canvaslogin {
/*   position: absolute; */
  width:100%;
  height:100%;
  --gradient-color-1: #a1c4fd; 
  --gradient-color-2: #c2e9fb ; 
  --gradient-color-3: #FFF2EE;  
  --gradient-color-4: #541c8b;
  padding: 1rem;
  
 /* 
   clip-path: polygon(0 0, 0% 86%, 100% 0); */
  
  
  }

  #canvasloginLogo {
      position: absolute;
      z-index:999;
      top: 50%;
      transform: translate(38%, -50%);

  }


.alert {
height: 100vh;
width: 100%;
position: fixed;
top: 0;
left: 0;
z-index: 9999;
transition: all .3s;
-webkit-backdrop-filter: blur(10px);
backdrop-filter: blur(10px);
background-color: rgba(#111, .3);

}

.alert__content {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
padding: 3rem;
width: 30%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-color: #fff;
box-shadow: 0 2rem 4rem rgba(17, 17, 17, 0.233);

overflow: hidden;
transition: all .5s .2s;
border-radius: 15px;
}

.alert__content--bigger {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
padding: 3rem;
width: 50%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-color: #fff;
box-shadow: 0 2rem 4rem rgba(17, 17, 17, 0.233);

overflow: hidden;
transition: all .5s .2s;
border-radius: 15px;
}
.alert__close {
position: absolute;
right: 2rem;
top: 1rem;
}

.alert__content-big {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);

width: 60%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-color: #fff;
box-shadow: 0 2rem 4rem rgba(17, 17, 17, 0.233);

overflow: hidden;
transition: all .5s .2s;
border-radius: 15px;
}
.alert-success {
background-color: #1ec034bc;
}
.alert-error{
background-color: #db1a0cab;
}

.hoveropacity {
transition: all .2s;
}
.hoveropacity:hover {
opacity: 0.6;

}






.dropdown-stylish {
position: absolute;
  top: 20px;
  right:10px;
margin: 0 auto;
width: 220px;
border: 1px solid #dcdcdc;
background: #f5f4fe;
z-index: 10;
}

.dropdown-stylish__text {
color: #656565;
padding: 8px 10px;
cursor: pointer;
height: auto;
}

.dropdown-stylish__text:hover {
color: #656565;
background: #f5f4fe;
}

.dropdown-stylish__text:after {
content: "";
transition: all .3s;
border: solid #ccc;
border-width: 0 1px 1px 0;
float: right;
margin-top: 8px;
margin-right: 6px;
padding: 5px;
transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);

}

.dropdown-stylish.active .dropdown-stylish__text:after {
margin-top:1px;
transform: rotate(45deg);
-webkit-transform: rotate(45deg);

}

.dropdown-stylish__items {
position: relative;
visibility: hidden;
opacity: 0;
max-height: 0px;
transition: max-height 0.6s ease;
}


.dropdown-stylish.active .dropdown-stylish__items {
visibility: visible;

border-top: 1px solid #dcdcdc;
height: auto;
max-height: 290px;
opacity: 1;
transition: max-height 0.7s, opacity 3s, visibility 4s ease;



}

.dropdown-stylish__item {
cursor: pointer;
padding: 8px 10px;
}

.dropdown-stylish__item:not(:last-child) {
border-bottom: 1px solid #dcdcdc;

}



.dropdown-stylish__item:hover {
color: #656565;
background: #f5f4fe;
}

.nomlop {
opacity: 1;
}
.lowop {
opacity: 0.7;


}
.cbimp {
color: black !important;
}

.greentext {
color: rgb(3, 213, 3) !important;
font-weight: 700;
}
.redtext {
color: red !important;
font-weight: 600;
}


.wrapper-searchBar {
display: flex;
flex-direction: row;
align-items: center;
/*  justify-content: space-between; */
margin: 0 1rem;
width: 100%;
/*   max-width: 31.25rem;
margin: 6rem auto; */
}

.label-searchBar {
font-size: 1rem;
font-weight: 400;
text-transform: uppercase;
letter-spacing: +1.3px;
margin-bottom: 1rem;

}

.searchBar {

max-width: 100%;
display: flex;
flex-direction: row;

align-items: center;
}

#searchQueryInput {

width: 100%;
height: 2.8rem;
background: #f5f5f5;
outline: none;
border: none;
border-radius: 1.625rem;
padding: 0 3.5rem 0 1.5rem;
font-size: 1rem;
}

#searchQuerySubmit {
width: 3.5rem;
height: 2.8rem;
margin-left: -3.5rem;
background: none;
border: none;
outline: none;
}

#searchQuerySubmit:hover {
cursor: pointer;
}

.searchBarDropdown {
margin-left: 2rem;
margin-right: 2rem;
}
.matchLabels {
font-size: .8rem;
margin-left: .7rem;
}
#unmatched {
margin-left: 2rem;
}
.searchBarNext {
display: flex;
align-items: center;
}

.createUser {
  display: grid;
  grid-template-columns: 10rem 35rem;
  grid-gap: 1.5rem;
  padding: 3rem;
  box-shadow: 0 2rem 4rem rgba(17, 17, 17, 0.233);
}
button:disabled {
  opacity: .7;
  cursor: not-allowed;
}